import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as GatsbyLink } from 'gatsby';

import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';

import imgDots from '../assets/images/life-in-barcelona/Dots.svg';
import imgPhoto1 from '../assets/images/life-in-barcelona/eighth.jpg';
import imgPhoto2 from '../assets/images/life-in-barcelona/Photo 2 (2).png';
import imgPhoto3 from '../assets/images/life-in-barcelona/sixth.jpg';
import imgReview from '../assets/images/life-in-barcelona/seventh.jpg';

import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/life-in-barcelona-page.scss';

const LifeInBarcelona = () => {
  return (
    <Layout title={{ id: 'barcelona.seo.title' }} description={{ id: 'barcelona.seo.description' }}>
      <div className="life-in-barcelona-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="barcelona.sec1.title" />
            </h1>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-md-first order-last">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">
                    <FormattedMessage id="barcelona.sec2.title" />
                  </h2>
                  <p className="c-text-18 block__subtitle">
                    <FormattedMessage
                      id="barcelona.sec2.description"
                      // values={{
                      //   accentedLink: (chunk) => (
                      //     <FormattedMessage id="url.tefl-spanish-course-barcelona">
                      //       {(locale) => (
                      //         <Link className="red-link" to={locale[0]}>
                      //           {chunk}
                      //         </Link>
                      //       )}
                      //     </FormattedMessage>
                      //   ),
                      // }}
                    />
                  </p>
                  <p className="c-text-16 block__description">
                    <FormattedMessage id="barcelona.sec2.text1" />
                    <br />
                    <br />
                    <FormattedMessage
                      id="barcelona.sec2.text2"
                      values={{
                        strong: (chunk) => <strong>{chunk}</strong>,
                      }}
                    />
                  </p>
                  <a
                    href={fileTEFLIberiaBrochure}
                    className="c-btn c-btn--red"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="homepage.sec6.downloadBrochure" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-md-last order-first">
                <div className="block_r">
                  <div className="block_r__img">
                    <img loading="lazy" src={imgPhoto1} alt="Barcelona" className="block_r__img" />
                  </div>

                  <div className=" block_r__trinity">
                    <img loading="lazy" src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section">
          <div className="container">
            <div className="wrapper">
              <div className="row top">
                <div className="col-md-6">
                  <div className="item">
                    <h2 className="item__title">
                      <FormattedMessage id="barcelona.sec3.titleL" />
                    </h2>
                    <p className="item__description">
                      <FormattedMessage id="barcelona.sec3.textL1" />
                      <br />
                      <br />
                      <FormattedMessage id="barcelona.sec3.textL2" />
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <h2 className="item__title">
                      <FormattedMessage id="barcelona.sec3.titleR" />
                    </h2>
                    <p className="item__description">
                      {/* The Spanish economy is now one of the fastest growing large economies in
                      Europe. What’s more, the ability to speak English is increasingly in-demand
                      for large and small businesses throughout the city. This is helped in no small
                      part by the fact that
                      <a
                        className="red-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.eu-startups.com/2022/12/barcelona-the-past-present-and-future-of-a-european-startup-hub/"
                      >
                        {' '}
                        Barcelona is now one of the best cities for startups in Europe{' '}
                      </a>
                      , many of which use English as a primary language. */}
                      <FormattedMessage
                        id="barcelona.sec3.textR1"
                        values={{
                          accentedLink: (chunk) => (
                            <a
                              className="red-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.eu-startups.com/2022/12/barcelona-the-past-present-and-future-of-a-european-startup-hub/"
                            >
                              {chunk}
                            </a>
                          ),
                        }}
                      />
                      <br />
                      <br />
                      <FormattedMessage
                        id="barcelona.sec3.textR2"
                        values={{
                          accentedLink: (chunk) => (
                            <FormattedMessage id="url.courses.index">
                              {(locale) => (
                                <Link className="red-link" to={locale[0]}>
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="wrapper-bottom">
                <div className="row cost-of-living">
                  <div className="col-md-6">
                    <div className="block_l">
                      <h2 className="c-title-32 block_l__title">
                        <FormattedMessage id="barcelona.sec3.title" />
                      </h2>
                      <p className="c-text-18 block_l__description">
                        <FormattedMessage id="barcelona.sec3.description" />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="block_r">
                      <p className="block_r__description c-text-18">
                        <FormattedMessage id="barcelona.sec3.fact" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row options">
                  <div className="table-content">
                    <div className="line line--top">
                      <p className="first">
                        <FormattedMessage id="barcelona.sec3.item" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="barcelona.sec3.barcelona" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="barcelona.sec3.london" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="barcelona.sec3.newYork" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="barcelona.sec3.austin" />
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="barcelona.sec3.bedroomApartment" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.barcelona" />:
                        </span>{' '}
                        569€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.london" />:
                        </span>{' '}
                        1263€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.newYork" />:
                        </span>{' '}
                        2154€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.austin" />:
                        </span>{' '}
                        1460€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="barcelona.sec3.mealInExpensive" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.barcelona" />:
                        </span>{' '}
                        14€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.london" />:
                        </span>{' '}
                        17€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.newYork" />:
                        </span>{' '}
                        23.55€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.austin" />:
                        </span>{' '}
                        16.96€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="barcelona.sec3.cappuccino" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.barcelona" />:
                        </span>{' '}
                        2.21€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.london" />:
                        </span>{' '}
                        3.82€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.newYork" />:
                        </span>{' '}
                        4.89€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.austin" />:
                        </span>{' '}
                        4.76€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="barcelona.sec3.beer" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.barcelona" />:
                        </span>{' '}
                        3€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.london" />:
                        </span>{' '}
                        6.82€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.newYork" />:
                        </span>{' '}
                        7.54€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.austin" />:
                        </span>{' '}
                        5.65€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="barcelona.sec3.metroTicket" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.barcelona" />:
                        </span>{' '}
                        1.10€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.london" />:
                        </span>{' '}
                        2.96€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.newYork" />:
                        </span>{' '}
                        2.59€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.austin" />:
                        </span>{' '}
                        1.40€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="barcelona.sec3.kmInTaxi" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.barcelona" />:
                        </span>{' '}
                        2€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.london" />:
                        </span>{' '}
                        1.93€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.newYork" />:
                        </span>{' '}
                        1.75€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="barcelona.sec3.austin" />:
                        </span>{' '}
                        1.46€
                      </p>
                    </div>
                  </div>
                </div>
                <a href="http://www.numbeo.com/" className="grey-link">
                  <FormattedMessage id="barcelona.sec3.source" />:{' '}
                  <span className="underline">http://www.numbeo.com/</span>
                </a>
                <p className="notice">
                  <FormattedMessage id="barcelona.sec3.notification" />
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage id="barcelona.sec4.title" />
                  </h2>
                  <p className="block_l__description c-text-18">
                    <FormattedMessage id="barcelona.sec4.description" />
                  </p>
                  <p className="block_l__text">
                    {/* Delivering quality TEFL courses is our business, but we are also here to guide
                    your entire experience. We’ve got your back from the very first step, with
                    expert assistance when it comes to getting your{' '}
                    <Link className="red-link" to="/working-as-a-teacher/student-visas/">
                      student visa
                    </Link>{' '}
                    and we’ll also help you{' '}
                    <Link className="red-link" to="/blog/do-i-need-a-spanish-bank-account/">
                      open your Spanish bank account
                    </Link>
                    . Our in-house accommodation service will help you get settled{' '}
                    <Link className="red-link" to="/accommodation/">
                      in the city
                    </Link>
                    , while our dedicated careers service will help you secure work post graduation.
                    <FormattedMessage */}
                    <FormattedMessage
                      id="barcelona.sec4.text1"
                      values={{
                        accentedLink: (chunk) => (
                          <FormattedMessage id="url.working-as-a-teacher.student-visas">
                            {(locale) => (
                              <Link className="red-link" to={locale[0]}>
                                {chunk}
                              </Link>
                            )}
                          </FormattedMessage>
                        ),
                        accentedLink2: (chunk) => (
                          <GatsbyLink
                            className="red-link"
                            to="/blog/do-i-need-a-spanish-bank-account/"
                          >
                            {chunk}
                          </GatsbyLink>
                        ),
                        accentedLink3: (chunk) => (
                          <FormattedMessage id="url.accommodation">
                            {(locale) => (
                              <Link className="red-link" to={locale[0]}>
                                {chunk}
                              </Link>
                            )}
                          </FormattedMessage>
                        ),
                      }}
                    />
                    <br />
                    <br />
                    <FormattedMessage id="barcelona.sec4.text2" />
                    <br />
                    <br />
                    <FormattedMessage id="barcelona.sec4.text3" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img loading="lazy" src={imgPhoto2} alt="Barcelona" className="block_r__img" />
                  <img loading="lazy" src={imgPhoto3} alt="imgDots" className="secondary-img" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fifth-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="block_l">
                  <h2 className="c-title-34 block_l__title">
                    <FormattedMessage id="barcelona.sec5.title" />
                  </h2>
                  <p className="c-text-18 block_l__description">
                    <FormattedMessage
                      id="barcelona.sec5.description1"
                      values={{
                        strong: (chunk) => <strong>{chunk}</strong>,
                      }}
                    />
                    <br />
                    <br />
                    <FormattedMessage id="barcelona.sec5.description2" />
                  </p>
                  <div className="block_l__img">
                    <img loading="lazy" src={imgReview} alt="Barcelona" />
                  </div>
                  <p className="img-comment">
                    Tibidabo and Parc de Collserola – always a great idea.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block_r">
                  <h3 className="block_r__title">
                    <FormattedMessage id="barcelona.sec5.thing1.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="barcelona.sec5.thing1.text" />{' '}
                    <h4>
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <GatsbyLink to="/blog/primavera-sound/" className="red-link">
                        <FormattedMessage id="barcelona.sec5.thing1.link1" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/5-spanish-festivals-youve-never-heard-of/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing1.link2" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/the-5-best-bars-in-el-raval-barcelona/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing1.link3" />
                      </GatsbyLink>{' '}
                    </p>
                    {/* <span className="author"> (Submitted by Maria)</span> */}
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="barcelona.sec5.thing2.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="barcelona.sec5.thing2.text" />
                    <h4>
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <GatsbyLink
                        to="/blog/top-5-outdoor-activities-in-catalonia/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing2.link1" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/best-day-trips-and-weekend-trips-from-barcelona-by/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing2.link2" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/the-five-best-beaches-near-barcelona/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing2.link3" />
                      </GatsbyLink>{' '}
                    </p>
                    {/* <span className="author">(Submitted by Claire)</span> */}
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="barcelona.sec5.thing3.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="barcelona.sec5.thing3.text" />
                    <h4>
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <GatsbyLink
                        to="/blog/a-foodie-guide-to-traditional-cuisine-in-barcelona/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing3.link1" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink to="/blog/vegan-guide-to-barcelona/" className="red-link">
                        <FormattedMessage id="barcelona.sec5.thing3.link2" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/the-five-best-brunches-in-barcelona/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing3.link3" />
                      </GatsbyLink>{' '}
                    </p>
                    {/* <span className="author">(Submitted by Richard)</span> */}
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="barcelona.sec5.thing4.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage
                      id="barcelona.sec5.thing4.text"
                      values={{
                        accentedLink: (chunk) => (
                          <a
                            target="_blank"
                            to="https://www.fcbarcelona.com/en/tickets/camp-nou-experience"
                            className="red-link"
                          >
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                    <h4>
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <GatsbyLink to="/blog/gym-alternatives-in-barcelona/" className="red-link">
                        <FormattedMessage id="barcelona.sec5.thing4.link1" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/the-five-best-work-out-spots-in-barcelona/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing4.link2" />
                      </GatsbyLink>{' '}
                    </p>
                    {/* <span className="author">(Submitted by James)</span> */}
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="barcelona.sec5.thing5.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="barcelona.sec5.thing5.text" />
                    <h4>
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <GatsbyLink to="/blog/the-best-bookshops-in-barcelona/" className="red-link">
                        <FormattedMessage id="barcelona.sec5.thing5.link1" />
                      </GatsbyLink>{' '}
                    </p>
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="barcelona.sec5.thing6.title" />
                  </h3>
                  <p className="block_r__description">
                    {/* Once you’ve been in the city for a while and the honeymoon period comes to an
                    end, you’ll find a deeper appreciation for local life. Soon you’ll be regaling
                    your friends and family with stories of winter calçotadas and local festa
                    majors, while discovering that you’re{' '}
                    <Link
                      to="/blog/ten-weird-habits-you-pick-up-when-you-live-in-spain/"
                      className="red-link"
                    >
                      picking up the habits of the locals!
                    </Link> */}
                    <FormattedMessage
                      id="barcelona.sec5.thing6.text"
                      values={{
                        accentedLink: (chunk) => (
                          <GatsbyLink
                            to="/blog/ten-weird-habits-you-pick-up-when-you-live-in-spain/"
                            className="red-link"
                          >
                            {chunk}
                          </GatsbyLink>
                        ),
                      }}
                    />
                    <h4>
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <GatsbyLink
                        to="/blog/our-favourite-terraces-in-barcelona/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing6.link1" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/barcelona-where-to-go-for-vermouth-2018/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing6.link2" />
                      </GatsbyLink>{' '}
                    </p>
                    <p>
                      <GatsbyLink
                        to="/blog/the-tefl-iberia-guide-to-neighbourhoods-of-barcelona/"
                        className="red-link"
                      >
                        <FormattedMessage id="barcelona.sec5.thing6.link3" />
                      </GatsbyLink>{' '}
                    </p>
                    <br />
                    <p>
                      <FormattedMessage
                        id="barcelona.sec5.moreInfo"
                        values={{
                          strong: (chunk) => <strong>{chunk}</strong>,
                          accentedLink: (chunk) => (
                            <FormattedMessage id="url.blog">
                              {(locale) => (
                                <Link to={locale[0]} className="red-link">
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                      {/* For more about living and teaching English in Barcelona,{' '}
                      <strong>
                        take a look at one of our{' '}
                        <Link to="/blog/" className="red-link">
                          recent blog posts.
                        </Link>
                      </strong> */}
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default LifeInBarcelona;
